<template>
  <div class="bg-gray-200 min-h-screen-action-bar">
    <div class="py-10 px-5 sm:px-10 md:px-20 text-gray-800 mx-auto max-w-full md:max-w-6xl">
      <div class="text-center text-3xl font-light">{{ $t('idc_welcome') }}</div>
      <div class="text-center font-bold text-primary-500 mb-10">{{ $t('idc_welcome_sub_title') }}</div>
      <div class="flex flex-col md:flex-row">
        <Card class="p-5 px-10 w-full md:w-1/2 mr-3 mb-5">
          <template slot="header">
            <div class="text-center text-2xl font-light mb-3">{{ $t('idc_chart_title') }}</div>
            <div class="text-sm font-normal">{{ $t('idc_chart_description') }}</div>
          </template>
          <DataCenterChartIcon class="w-full h-auto my-8"/>
          <template slot="footer">
            <Button :to="{ name: 'datacenter-chart' }" class="mx-auto max-w-xs" primary>{{ $t('start') }}</Button>
          </template>
        </Card>
        <Card class="p-5 px-10 w-full md:w-1/2 mb-5">
          <template slot="header">
            <div class="text-center text-2xl font-light mb-3">{{ $t('idc_list_title') }}</div>
            <div class="text-sm font-normal">{{ $t('idc_list_description') }}</div>
          </template>
          <DataCenterListIcon class="w-full h-auto my-8"/>
          <template slot="footer">
            <Button :to="{ name: 'datacenter-list' }" class="mx-auto max-w-xs" primary>{{ $t('start') }}</Button>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import DataCenterChartIcon from '@/ui/views/DataCenter/components/DataCenterChartIcon.vue';
import DataCenterListIcon from '@/ui/views/DataCenter/components/DataCenterListIcon.vue';

export default {
  components: { DataCenterListIcon, DataCenterChartIcon },
};
</script>

<style scoped>
</style>
